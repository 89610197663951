*, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #111318;
  background-color: #F1EEE7;
  margin: 0;
  padding: 20px;
}

h1, h2, h3, h4, h5, h6 {
  color: #344E6B;
  font-weight: 900;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

p, ul, ol, li {
  max-width: 720px;
  margin-right: auto;
  margin-left: 0;
  text-align: left;
}

ul, ol {
  padding-left: 220px;
}

li {
  margin-bottom: 11px;
}

hr {
  background-color: #1A2736;
  height: 3px;
  margin: 33px 0;
}

@media print {
  body {
    background-color: white;
  }
  
  .tech-spec-sheet {
    background-color: white !important;
  }
}

.App {
  background-color: #F3F8F7;
  padding: 33px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #111318;
  width: 100%;
  margin: 20px auto;
}

@media screen and (min-width: 768px) {
  .App.tech-spec-page {
    width: 99%;
    max-width: 2560px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  body {
    padding: 0;
  }

  .App {
    width: 100%;
    margin: 0;
    padding: 20px;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  p, ul, li {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .App {
    width: 80%;
    max-width: 80%;
    margin: 20px auto;
  }
}

@media screen and (min-width: 1024px) {
  .App {
    width: 62%;
    max-width: 62%;
    margin: 20px auto;
  }
}

button {
  background-color: #14766A;
  color: #F1EEE7;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  border-radius: 7px;
  font-size: 18px;
  margin: 11px 11px 11px 0;
}

button:hover {
  background-color: #DD5B37;
}

button:active {
  background-color: #7C3C43;
}

button.active {
  background-color: #3E1E22;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #7C3C43;
  font-weight: bold;
  font-size: 18px;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.item-number {
  background-color: #344E6B;
  color: #F1EEE7;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
}

.item-inputs {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 40px;
}

.item-inputs .input-group {
  margin-bottom: 15px;
}

.item-inputs label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.item-inputs input[type="text"],
.item-inputs input[type="number"],
.item-inputs select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.item-inputs .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.item-inputs .checkbox-group > div {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
}

.item-inputs .checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.item-inputs .checkbox-group input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  /* Remove transition and simplify focus styles */
  &:focus + .checkmark {
    outline: 2px solid -webkit-focus-ring-color;
    outline-offset: 2px;
  }
}

/* Ensure the label has a relative positioning for proper outline display */
.item-inputs .checkbox-group label {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
}

.item-inputs .checkbox-group .checkmark {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border: 2px solid #14766A;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Remove transition property */
}

.item-inputs .checkbox-group input[type="checkbox"]:checked + .checkmark {
  background-color: #14766A;
}

.item-inputs .checkbox-group .checkmark:after {
  content: '\2714';
  color: white;
  font-size: 14px;
  display: none;
}

.item-inputs .checkbox-group input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}

/* Remove hover effect */
.item-inputs .checkbox-group label:hover .checkmark {
  box-shadow: none;
}

.item-inputs .input-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.item-inputs .input-row > div {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
}

.item-inputs .input-row > div:last-child {
  padding-right: 0;
}

.item-inputs .input-group input[type="number"] {
  width: 100%;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox-group > div {
  margin-right: 20px;
  margin-bottom: 10px;
}

.checkbox-group .group-label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  color: #7C3C43;
}

.checkbox-options {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-options > div {
  margin-right: 20px;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .item-inputs .input-row {
    flex-wrap: wrap;
  }
  
  .item-inputs .input-group,
  .item-inputs .checkbox-group {
    flex-basis: 25%;
    min-width: 0;
  }
}

textarea[name="additionalNotes"] {
  height: 100px;
  resize: vertical;
}

.spec-sheet-page {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

.spec-sheet-page #tech-spec-sheet {
  width: 100%;
}

.spec-sheet-page .app-button {
  margin-top: 20px;
}

.spec-sheet-page .content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.spec-sheet-page.App,
.App .spec-sheet-page {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

.spec-sheet-page .content-wrapper {
  width: 100%;
  max-width: none;
}

.spec-sheet-body .App {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

.tech-spec-sheet table {
  width: 100%;
  border-collapse: collapse;
}

.tech-spec-sheet th,
.tech-spec-sheet td {
  padding: 8px;
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  border: 1px solid #ddd;
}

.tech-spec-sheet th {
  background-color: #344E6B;
  color: #F1EEE7;
  font-weight: bold;
}

.channel-plot-table .channel-column {
  width: 7%;
}

.channel-plot-table .name-column {
  width: 13%;
}

.channel-plot-table .group-column {
  width: 13%;
}

.channel-plot-table .mic-type-column {
  width: 15%;
}

.channel-plot-table .fx-column {
  width: 23%;
}

.channel-plot-table .notes-column {
  width: auto;
}

.members-table .name-column {
  width: 17%;
}

.members-table .instrument-column {
  width: 17%;
}

.members-table .vox-column,
.members-table .di-column,
.members-table .mics-column,
.members-table .power-column,
.members-table .instrument-mic-column,
.members-table .vmic-column {
  width: 7%;
}

.members-table .notes-column {
  width: auto;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tech-spec-sheet table {
  min-width: 600px; /* Adjust this value based on your table's content */
}

.remove-item-btn,
.remove-channel-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.remove-item-btn:hover,
.remove-channel-btn:hover {
  background-color: #ff3333;
}

.home-page {
  text-align: center;
  padding: 20px;
}

.logo-container {
  width: 100%;
  max-width: 949px;
  margin: 0 auto 33px;
}

.logo {
  margin: 0;
  padding: 0;
}

.logo img {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (min-width: 768px) {
  .logo-container {
    width: 33%;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 33px;
  }
}

@media screen and (max-width: 767px) {
  .logo-container {
    max-width: 62%;
    margin-left: 0;  /* This will left-align the logo on mobile */
    margin-right: auto;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .app-header {
    text-align: left;
  }
}

.checkbox-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: #14766A;
  color: #F1EEE7;
  border: none;
  border-radius: 7px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-btn:hover {
  background-color: #DD5B37;
}

.submit-btn:active {
  background-color: #7C3C43;
}

.instructions {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.instructions h3 {
  margin-top: 0;
  color: #333;
}

.instructions h4 {
  margin-bottom: 10px;
  color: #444;
}

.instructions ol {
  padding-left: 20px;
}

.instructions li {
  margin-bottom: 5px;
}

.instructions p {
  margin-bottom: 10px;
}

.form-instructions {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-instructions summary {
  padding: 10px;
  cursor: pointer;
  background-color: #f5f5f5;
  font-weight: bold;
}

.form-instructions summary:hover {
  background-color: #e9e9e9;
}

.form-instructions[open] summary {
  border-bottom: 1px solid #ddd;
}

.form-instructions > div {
  padding: 15px;
}

.form-instructions h3 {
  margin-top: 0;
}

.form-instructions h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-instructions ol {
  padding-left: 20px;
}

.form-instructions p:last-child {
  margin-bottom: 0;
}

.reset-btn {
  background-color: #7C3C43;
}

@media screen and (max-width: 767px) {
  .mobile-tech-spec {
    padding: 10px;
  }

  .mobile-channel-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .mobile-channel-card summary {
    padding: 10px;
    background-color: #eee;
    font-weight: bold;
    cursor: pointer;
  }

  .mobile-channel-card summary::-webkit-details-marker {
    display: none;
  }

  .mobile-channel-card summary::after {
    content: '\25BC';
    float: right;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }

  .mobile-channel-card[open] summary::after {
    transform: rotate(180deg);
  }

  .mobile-channel-details {
    padding: 10px;
  }

  .mobile-channel-details p {
    margin: 5px 0;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tech-spec-sheet table {
  width: 100%;
  min-width: 600px; /* Adjust this value based on your table's content */
}

@media screen and (max-width: 767px) {
  .tech-spec-sheet {
    font-size: 14px; /* Slightly smaller font size for mobile */
  }

  .tech-spec-sheet th,
  .tech-spec-sheet td {
    padding: 8px 6px; /* Slightly less padding for mobile */
  }
}

.tech-spec-sheet {
  overflow-x: scroll;
}

.item-number {
    background-color: #344E6B;
    color: #F1EEE7;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 12px;
  }


  .item-inputs {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    padding-bottom: 40px; /* Adjust this value as needed to create space for the button */
  }
  
  .item-inputs .input-group {
    margin-bottom: 15px;
  }
  
  .item-inputs label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .item-inputs input[type="text"],
  .item-inputs input[type="number"],
  .item-inputs select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .item-inputs .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .item-inputs .checkbox-group > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .item-inputs .checkbox-group label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
  }
  
  .item-inputs .checkbox-group input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    /* Remove transition and simplify focus styles */
    &:focus + .checkmark {
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: 2px;
    }
  }
  
  .item-inputs .checkbox-group .checkmark {
    width: 22px;
    height: 22px;
    background-color: #fff;
    border: 2px solid #14766A;
    border-radius: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Remove transition property */
  }
  
  .item-inputs .checkbox-group input[type="checkbox"]:checked + .checkmark {
    background-color: #14766A;
  }
  
  .item-inputs .checkbox-group .checkmark:after {
    content: '\2714';
    color: white;
    font-size: 14px;
    display: none;
  }
  
  .item-inputs .checkbox-group input[type="checkbox"]:checked + .checkmark:after {
    display: block;
  }
  
  /* Remove hover effect */
  .item-inputs .checkbox-group label:hover .checkmark {
    box-shadow: none;
  }

  .item-inputs .input-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .item-inputs .input-row > div {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .item-inputs .input-row > div:last-child {
    padding-right: 0;
  }

  .item-inputs .input-group input[type="number"] {
    width: 100%;
  }

  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }

  .checkbox-group > div {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .checkbox-group .group-label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    color: #7C3C43;  /* This matches the label color we set earlier */
  }

  .checkbox-options {
    display: flex;
    flex-wrap: wrap;
  }

  .checkbox-options > div {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    .item-inputs .input-row {
      flex-wrap: wrap;
    }
    
    .item-inputs .input-group,
    .item-inputs .checkbox-group {
      flex-basis: 25%;
      min-width: 0;
    }
  }

textarea[name="additionalNotes"] {
  height: 100px;
  resize: vertical;
}

.spec-sheet-page {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

.spec-sheet-page #tech-spec-sheet {
  width: 100%;
}

/* Adjust the button layout if needed */
.spec-sheet-page .app-button {
  margin-top: 20px;
}

/* If you want to limit the width of the content inside the spec sheet */
.spec-sheet-page .content-wrapper {
  max-width: 1200px;  /* or any other value that suits your design */
  margin: 0 auto;
}

/* Target the .App class when it's a parent of .spec-sheet-page */
.spec-sheet-page.App,
.App .spec-sheet-page {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

/* Ensure the content inside takes full width */
.spec-sheet-page .content-wrapper {
  width: 100%;
  max-width: none;
}

/* Ensure the .App container is set to 100% width only when the SpecSheetPage is rendered */
.spec-sheet-body .App {
  width: 100%;
  max-width: none;
  padding: 20px;
  box-sizing: border-box;
}

.tech-spec-sheet table {
  width: 100%;
  border-collapse: collapse;
}

.tech-spec-sheet th,
.tech-spec-sheet td {
  padding: 8px;
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  border: 1px solid #ddd;
}

.tech-spec-sheet th {
  background-color: #344E6B;
  color: #F1EEE7;
  font-weight: bold;
}

/* Channel plot table specific styles */
.channel-plot-table .channel-column {
  width: 7%;
}

.channel-plot-table .name-column {
  width: 13%;
}

.channel-plot-table .group-column {
  width: 13%;
}

.channel-plot-table .mic-type-column {
  width: 15%;
}

.channel-plot-table .fx-column {
  width: 23%;
}

.channel-plot-table .notes-column {
  width: auto;
}

/* Members table specific styles */
.members-table .name-column {
  width: 17%;
}

.members-table .instrument-column {
  width: 17%;
}

.members-table .vox-column,
.members-table .di-column,
.members-table .mics-column,
.members-table .power-column,
.members-table .instrument-mic-column,
.members-table .vmic-column {
  width: 7%;
}

.members-table .notes-column {
  width: auto;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tech-spec-sheet table {
  min-width: 600px; /* Adjust this value based on your table's content */
}

.remove-item-btn,
.remove-channel-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.remove-item-btn:hover,
.remove-channel-btn:hover {
  background-color: #ff3333;
}

.home-page {
  text-align: center;
  padding: 20px;
}

.logo-container {
  width: 100%;
  max-width: 949px;
  margin: 0 auto 33px;
}

.logo {
  margin: 0;
  padding: 0;
}

.logo img {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (min-width: 768px) {
  .logo-container {
    width: 33%;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 33px;
  }
}

@media screen and (max-width: 767px) {
  .logo-container {
    max-width: 62%;
    margin-left: 0;  /* This will left-align the logo on mobile */
    margin-right: auto;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Ensure the header content is aligned left on desktop */
@media screen and (min-width: 768px) {
  .app-header {
    text-align: left;
  }
}

.checkbox-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;  /* Adjust this value to control spacing between checkboxes */
}

.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: #14766A;
  color: #F1EEE7;
  border: none;
  border-radius: 7px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-btn:hover {
  background-color: #DD5B37;
}

.submit-btn:active {
  background-color: #7C3C43;
}

.instructions {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.instructions h3 {
  margin-top: 0;
  color: #333;
}

.instructions h4 {
  margin-bottom: 10px;
  color: #444;
}

.instructions ol {
  padding-left: 20px;
}

.instructions li {
  margin-bottom: 5px;
}

.instructions p {
  margin-bottom: 10px;
}

.form-instructions {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-instructions summary {
  padding: 10px;
  cursor: pointer;
  background-color: #f5f5f5;
  font-weight: bold;
}

.form-instructions summary:hover {
  background-color: #e9e9e9;
}

.form-instructions[open] summary {
  border-bottom: 1px solid #ddd;
}

.form-instructions > div {
  padding: 15px;
}

.form-instructions h3 {
  margin-top: 0;
}

.form-instructions h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.footer-container {
  width: 62%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.footer-container.tech-spec {
  width: 99%;
}

@media (max-width: 1040px) {
  .footer-container,
  .footer-container.tech-spec {
    width: 80%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .footer-container,
  .footer-container.tech-spec {
    width: 100%;
    padding: 0 20px;
    text-align: left;
  }
}